<script lang="ts">
    import { routes } from '$lib/routes';
    import { page } from '$app/stores';
    import type { FooterFragment, GlobalContentQuery, MenuFragmentFragment } from '$gen/graphql';
    import { afterNavigate, goto } from '$app/navigation';
    import BurgerIcon from './BurgerIcon.svelte';
    import KubaparisSshMobileToggle from './KubaparisSshMobileToggle.svelte';
    import { browser } from '$app/env';

    export let content: MenuFragmentFragment;
    export let postCategories: GlobalContentQuery['postCategories'];
    export let submissionPages: SubmissionPageType[];
    export let footer: FooterFragment;

    let submitpath = '/submit/exhibition';
    let submitIsCurrentPage = $page.url.pathname === submitpath;

    let open = false;
    let showCategories = false;
    let showSubmissions = false;
    let searchValue: string = '';

    $: submitIsCurrentPage = $page.url.pathname === submitpath;

    $: if (open === false) searchValue = '';
    $: if (browser) {
        open === true ? fixBody() : unfixBody();
    }

    $: subpages = content.menuSubpages.filter((item) => {
        // Filter out subpages that point to submit/... because they are displayed separately.
        return !item.subpage[0]?.uri.startsWith('submit');
    });

    /** Make the menu fixed to the content can flow behind the menu. Use the submission success page. */
    $: position =
        open ||
        $page.url.pathname.startsWith(routes.submit_success) ||
        $page.url.pathname.startsWith(routes.advertise) ||
        $page.url.pathname.startsWith(routes.submission('', '')) ||
        $page.url.pathname.startsWith(routes.post_root)
            ? 'fixed'
            : 'sticky';
    $: whiteText = $page.url.pathname.startsWith(routes.submit_success);
    $: burgerIconWhiteStroke = $page.url.pathname.startsWith(routes.calendar);

    afterNavigate(() => {
        // Close the menu after navigating is done.
        open = false;
    });

    function fixBody() {
        // When the modal is shown, we want a fixed body
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
    }
    function unfixBody() {
        // When the modal is hidden...
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
</script>

<header
    class="sticky top-0 left-0 z-desktop-menu w-full overflow-y-auto lg:hidden"
    class:mobileheaderopen={open}
    style:height={open ? '100%' : 'auto'}
    style:position
    class:white-text={whiteText && !open}
>
    {#if open}
        <nav class="relative flex h-full flex-col justify-between p-5 text-black">
            {#if content}
                <ul id="main-menu" class="flex-grow text-3xl">
                    <li>
                        <a
                            href={routes.kubaparis}
                            sveltekit:prefetch
                            class:italic={$page.url.pathname === routes.kubaparis}
                        >
                            KUBAPARIS
                        </a>
                    </li>
                    <li>
                        <a
                            href={routes.ssh_projects}
                            sveltekit:prefetch
                            class:italic={$page.url.pathname === routes.ssh}
                        >
                            Studio SH
                        </a>
                    </li>
                    <!-- Categories -->
                    <li>
                        <button on:click={() => (showCategories = !showCategories)}>
                            Categories
                        </button>
                        <ul style:display={showCategories ? 'block' : 'none'}>
                            {#each postCategories as { uri, title, id } (id)}
                                {@const path = `/${uri}`}
                                {@const isCurrentPage = $page.url.pathname === path}
                                <li>
                                    <a
                                        href={path}
                                        class:italic={$page.url.pathname === path}
                                        sveltekit:prefetch
                                        on:click={() => {
                                            if (isCurrentPage) open = false;
                                        }}
                                    >
                                        #{title}
                                    </a>
                                </li>
                            {/each}
                        </ul>
                    </li>
                    <!-- Submission Form Pages -->
                    <li>
                        <a
                            href={submitpath}
                            class=""
                            class:italic={submitIsCurrentPage}
                            on:click={() => {
                                if (submitIsCurrentPage) open = false;
                            }}
                        >
                            Submit
                        </a>
                    </li>
                    <!-- Subpage links (internal) -->
                    {#each subpages as subpage (subpage.id)}
                        {@const path = `/${subpage.subpage[0].uri}`}
                        {@const isCurrentPage = $page.url.pathname === path}
                        <li>
                            <a
                                class:italic={$page.url.pathname === path}
                                class=""
                                href={path}
                                sveltekit:prefetch
                                on:click={() => {
                                    if (isCurrentPage) open = false;
                                }}
                            >
                                {subpage.label}
                            </a>
                        </li>
                    {/each}
           
                    <!--<li>
                        <a class="" sveltekit:prefetch href={routes.calendar}>Calendar!</a>
                    </li>-->
                </ul>

                <!-- Search input -->
                <div class="my-10 flex flex-row text-2xl">
                    <label for="search" class="cursor-pointer">Search </label>
                    <input
                        id="search"
                        name="search"
                        type="search"
                        class="mx-2 w-full max-w-none shrink grow border-b bg-transparent"
                        bind:value={searchValue}
                        on:keypress={(event) => {
                            if (event.key === 'Enter') {
                                goto(routes.search(searchValue));
                            }
                        }}
                    />
                </div>

                <!-- Social media links (external) -->
                <div class="my-10 grid place-content-center">
                    <ul id="social-media" class="flex flex-wrap justify-center gap-x-2 text-xl">
                        {#each content.socialMediaLinks as { link, label } (link + label)}
                            <li class="inline-block">
                                <a href={link} target="_blank" rel="external">{label}</a>
                            </li>
                        {/each}
                    </ul>
                </div>

                <!-- Footer -->
                <div class="flex flex-wrap justify-center gap-x-2 text-xl">
                    <ul id="internal-links" class=" flex flex-row space-x-2">
                        {#each footer.linkToSingles as link}
                            <li>
                                <a href={`/${link.uri}`} sveltekit:prefetch>{link.title}</a>
                            </li>
                        {/each}
                    </ul>
                </div>
            {/if}
        </nav>
    {:else}
        <KubaparisSshMobileToggle />
    {/if}
    <BurgerIcon bind:open whiteStroke={burgerIconWhiteStroke} />
</header>

<style>
    #main-menu {
        grid-column: main;
    }
    .mobileheaderopen {
        background-color: #FF4907;
    }

    #main-menu > li > a::after,
    #social-media li > a::after {
        content: '';
    }

    li:last-child > a::after {
        content: none !important;
    }

    .white-text {
        @apply text-white;
    }
</style>
